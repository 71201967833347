import * as billingDetailsHelper from './paycentral.billingdetails.helper.js';

// This class handles the client side logic for PayCentral
class PayCentralManager {
    constructor(address, containList, context, dataVaultJson, dataVaultResponseToken, frame, paymentMethod, paymentMethodSelector, validToken, commandButton, commandButtonOnClick) {
        this.address = address;
        this.containList = [];
        this.context = {};
        this.dataVaultJson = dataVaultJson;
        this.dataVaultResponseToken = dataVaultResponseToken;
        this.frame = frame;
        this.paymentMethod = {};
        this.paymentMethodSelector = paymentMethodSelector;
        this.validToken = validToken;
        this.commandButton = commandButton;
        this.commandButtonOnClick = commandButtonOnClick;
        this.options = {};
        this.overlay = null;
        this.frameContentCounter = 0;
        this.frameUnresponsive = false;
        this.pingCounter = 0;
        this.pingHandlers = [];
        this.src = null;
        this.lastOptionsData = {};

        // Catches the event when a user clicks the submit button
        document.addEventListener("beforeSubmit", () => {
            if (!window.PayCentral.sendPostMessage()) {
                window.PayCentral.fireSubmit();
            }
        }, false);

        // Catches the post sent from DV
        window.addEventListener("message", (e) => {
            // Warn if the incoming message origin does not match the expected pay central ui origin.
            const dvUrl = this.getTargetOrigin();
            if (e.origin !== dvUrl && dvUrl !== "*") {
                console.warn("e.origin = " + e.origin + "!= dvUrl = " + dvUrl);
                return;
            }

            window.PayCentral.receivePostMessage(e);

        }, false);
    }

    pmInstance = 0;
    /**
     * Called when the user changes the payment method
     */
    paymentMethodChanged() {
        this.pmInstance++;
    }

    // Handle the post message to the DV Widget
    sendPostMessage() {
        window.PayCentral.setup();
        if (!this.context.UseDatavault || !this.context.HasFrame || !this.context.HasPaymentMethod || !this.context.DoEnrollment || this.context.HasRequiredFieldErrors || this.context.isUKDD) return false; // Check context to decide whether to post or not
        window.PayCentral.updateJson(this.dataVaultJson.value); // Update must come before post so we have the correct frame and json values
        let dvurl = this.getTargetOrigin();
        if (dvurl === "") {
            return false;
        }
        if (this.frameUnresponsive) {
            this.handleFrameUnresponsive();
            return true;
        }
        this.frame.contentWindow.postMessage(this.dataVaultJson, dvurl); // Finally, post message to DV
        this.disablePage();
        return true;
    }

    /**
     * @desc Handle the message posted from DataVault.
     * @param {any} e
     */
    receivePostMessage(e) {

        // handle responses to ping
        if (typeof e.data === "string" && e.data.startsWith("pong-")) {
            this.handlePingResponse(e.data);
            return;
        }

        // Remove existing errors.
        this.clearMessages();

        const message = window.PayCentral.parsePostMessageEventData(e);
        switch (message.actionCode) {
            case "DV-RESPONSE":
                // Delayed capture.
                // Response message contains a tokenization response.
                if (message.json.TokenizationSuccessful === true) {
                    this.validToken = message.json;
                    this.dataVaultResponseToken.value = message.data;
                    this.threeDSCode.value = message.threeDsTransId;
                    window.PayCentral.fireSubmit();
                }
                // Immediate capture.
                this.dataVaultCaptureResult.value = message.data;
                window.PayCentral.fireSubmit();
                break;
            case "DV-CHALLENGE":
                // Handle the 3DS challenge display.
                if (message.data.toLowerCase().includes("sdkchallengepayload") && message.data.toLowerCase().includes("clientid")) {
                    // iATS provider.
                    window.PayCentral.iatsIssuerChallenge(message.json)
                        .then(response => {
                            if (response.isAuthenticated && response.threeDSCode) {
                                window.PayCentral.handle3dsComplete(response.threeDSCode);
                            } else {
                                window.PayCentral.handleError(response.errors);
                            }
                        });
                } else if (ThreeDsSdk && message.data.toLowerCase().includes("challengepayload")) {
                    // PayCentral provider.
                    ThreeDsSdk.display3dsChallenge(message.json,
                        (threeDsId) => window.PayCentral.handle3dsComplete(threeDsId),
                        (errors) => window.PayCentral.handleError(errors));
                }
                break;
            case "ERROR":
                window.PayCentral.handleError(message.data);
                break;
            case "NOACTION":
                this.enablePage();
            default:
                break;
        }
    }

    // TODO-AWELLS: This existing logic here is a bit clunky and might be worth improving. To make things easy on future developers I've added a brief explanation of the current postMessage structure & our parsing logic below.
    /**
     * @desc Evaluate incoming post message and return action code.
     *
     * Notes:
     * - post messages from data vault are prepended with 'dv-response:' or 'dv-challenge:'
     * - if the customer is shown a 3ds challenge window '#3ds#' is appended to the message content.
     * 1. split the content string using separator: ':' (there should only be one in the message)
     * 2. after the split index 0 will contain the prefix and index '1' the serialized json
     * 3. if the message contains a 3DS auth code/transaction id the string must be split again on separator: '#3ds#'
     * 4. at this point the string should contain valid serialized json ready to be parsed.
     * 5. attempt to parse the json - if an exception is thrown we must have an error string.
     *
     * @param {any} e
     */
    parsePostMessageEventData(e) {
        if (typeof (e.data) !== "string") return { actionCode: "NOACTION" };

        let message = {};
        const dataArray = e.data.split(/:(.+)/);
        const contentArray = dataArray[1].split("#3ds#");

        message.actionCode = dataArray[0].toUpperCase();
        message.data = contentArray[0];
        message.threeDsTransId = contentArray.length > 1 ? contentArray[1] : this.threeDSCode.value;

        try {
            message.json = JSON.parse(message.data);
        } catch (error) {
            message.actionCode = "ERROR";
        }

        return message;
    }

    /**
     * iATS issuer challenge
     * @desc Complete iATS Issuer Challenge (iATS challenge flow - Conditional)
     * @param {any} challenge
     */
    iatsIssuerChallenge(challenge) {
        console.group('iATS issuerChallenge');
        return window.iats3ds.getChallenge(challenge.clientID, challenge.sdkChallengePayload)
            .then(response => {
                switch (response.success) {
                    case true:
                        //then: no further authentication is required. AuthenticationCode3DS is needed for the transaction.
                        console.log('Success');
                        console.info('3DS: ' + response.authId);
                        challenge.threeDSCode = response.authId;
                        challenge.isAuthenticated = true;
                        break;

                    default:
                        //then: transaction processing cannot continue.get errorMessage parameter for associated errors.
                        if (response.errorMessage) {
                            console.error(response.errorMessage);
                            challenge.errors = response.errorMessage;
                        }
                        else
                            console.error('FAILED');
                        break;
                }

                console.groupEnd();
                return challenge;
            });
    }

    // Immediately after client click and before posting to DV, 
    // grab all necessary inputs + build the selected payment method + set the context to be used to decided whether we post to DV or not
    setup() {
        this.paymentMethodSelector = document.querySelector("select[id$='_PaymentMethodDropDown']") || document.querySelector("select[id$='PaymentMethodSelectBox']");
        this.frame = this.findFrame(); // DV hosted frame
        this.dataVaultResponseToken = document.querySelector("input[id$='DataVaultResponseToken']"); // Input populated when we receive a successful token from DV
        this.dataVaultJson = document.querySelector("input[id$='DataVaultJson']"); // Input populated with the json to be passed to DV   
        this.threeDSCode = document.querySelector("input[id$='ThreeDSCode']");
        this.dataVaultCaptureResult = document.querySelector("input[id$='DataVaultCaptureResult']");

        window.PayCentral.setContext();
    }

    findFrame() {
        return document.querySelector("iframe[id*='iFrame']");
    }

    // Before posting update all required fields in the json. Grabs values that are overwritten by user input
    // and sets the correct iFrame to post to
    updateJson(json) {
        if (json === null) return;
        const obj = JSON.parse(json);

        // Refresh billing details.
        if (obj.Address) {
            billingDetailsHelper.setBillingDetails(obj.Address, obj.Name, this.pmInstance);
        }
        const billingDetails = billingDetailsHelper.getBillingDetails();
        // If credit card payment replace Full Name with Cardholder name.
        if (this.isCreditCard()) {
            const cardholderNameFromInput = this.getCardholderNameFromInputField();
            if (cardholderNameFromInput)
                billingDetails.Name.FullName = cardholderNameFromInput;
        }


        let payload = {
            DvGatewayID:     this.paymentMethod.GatewayId,
            GatewayProvider: this.paymentMethod.GatewayName.toUpperCase(),
            IsRecurring:     this.getIsRecurring(),
            AccountType:     this.getBankAccountType(),
            Amount:          this.getAmount(),
            Currency:        this.paymentMethod.Currency.toUpperCase(),
            Name:            billingDetails.Name,
            Address:         billingDetails.Address,
            IatsAgentCode:   obj.IatsAgentCode,
            PayCentralUiUrl: this.PayCentralUiUrl,                     //TODO: Remove this??
            supports3DS:     { supports3Ds: false, utilize3Ds: false } //TODO: Remove this??
        };


        payload.paymentRequestOptions = this.options;
        payload.paymentRequestData = this.getPaymentRequestData();

        this.dataVaultJson = payload;
    }

    // Gateway provider setting to request 3DS authentication.
    supports3DS(currency, provider) {
        let supports3Ds = false;
        let utilize3Ds = false;

        if (provider === "IATS") {
            supports3Ds = true;
            utilize3Ds = (currency === "EUR" || currency === "GBP");
        }
        if (provider === "IMIS PAY") {
            supports3Ds = true;
            utilize3Ds = (currency === "EUR" || currency === "GBP");
        }

        return { supports3Ds, utilize3Ds };
    }

    // Handles updates to the address
    updateAddress(json) {

        if (json) {
            const address = typeof json === "object" ? json : JSON.parse(json.replace(/\r?\n|\r/g, ' '));
            billingDetailsHelper.updateBillingAddress(address, this.pmInstance);
            this.address = address;
            return;
        }

        if (this.address === undefined) {
            // Todo: is this still in use? does anyone know? the only references I see are test references.
            const addressInput = document.querySelector("input[id$='DataVaultAddress']");
            if (addressInput) {
                this.address = addressInput.value;
            }
        }
    }

    // Displays the validation message received from DV
    showValidation(msg, isHtml) {
        let errors;
        if (isHtml)
            errors = [msg];
        else if (isArray(msg))
            errors = [...msg];
        else if (typeof msg === "string" && msg)
            errors = msg.split('#');
        if (!errors) errors = ["Unknown error"];

        const messagecontainer = this.getMessageContainer();
        if (!messagecontainer) {
            console.warn("Could not display the following PayCentral messages as the message container is not visible.");
            console.warn(errors);
            return;
        }

        const div = document.createElement("div");
        div.style.marginBottom = "1em";

        errors.forEach((x) => {
            let span = document.createElement("span");
            if (isHtml)
                span.innerHTML = x;
            else
                span.textContent = x;
            span.setAttribute("style", "display:block;");
            span.setAttribute("class", "Important ValidationError");
            div.appendChild(span);
        });

        messagecontainer.appendChild(div);
    }

    getMessageContainer() {
        return document.querySelector(`div[id$='${this.options.ModuleTarget}'] span[id=PayCentralUserMessages]`);
    }

    clearMessages() {
        const messagecontainer = this.getMessageContainer();
        if (!messagecontainer) return;
        while (messagecontainer.lastElementChild) {
            messagecontainer.lastElementChild.remove();
        }
    }

    // Checks that all required inputs have been filled in - note this checks all controls with the aria-required attribute that exist in the
    // iMIS code not the iframe - this turns out (at the time of writing) to be simply the credit card name field - the effect of this check
    // failing is to not send any data to PayCentral 
    hasRequiredFieldErrors() {
        let ret = false;
        let creditCardPanelVisible = jQuery('#PayCentralDiv').find("div[id$='_CreditCardPanel']").is(':visible');
        let bankDraftPanelVisible = jQuery('#PayCentralDiv').find("div[id$='_BankDraftPanel']").is(':visible');

        if (creditCardPanelVisible) {
            let creditCardPanel = jQuery('#PayCentralDiv').find("div[id$='_CreditCardPanel']");
            let requiredElements = creditCardPanel.find('input[aria-required]');
            requiredElements.each(function (_x, y) {
                if (!y.value) {
                    ret = true;
                    return false;
                }
            });
            return ret;
        }

        if (bankDraftPanelVisible) {
            let bankDraftPanel = jQuery('#PayCentralDiv').find("div[id$='_BankDraftPanel']");
            let requiredElements = bankDraftPanel.find('input[aria-required]');
            requiredElements.each(function (_x, y) {
                if (!y.value) {
                    ret = true;
                    return false;
                }
            });
            return ret;
        }

        return ret;
    }

    // Used when tokenization fails, ensures the submit button will be re-enabled
    enableSubmitButton() {
        let saveButton = document.querySelector("input[ID$='_SaveButton']") || document.querySelector("input[ID$='_SubmitCartOrderButton']");
        if (saveButton) {
            saveButton.disabled = false;
        }
    }

    // Use context to determine whether we should post to DataVault
    setContext() {
        this.context.HasPaymentMethod = window.PayCentral.hasSelectedPaymentMehtod(this.paymentMethodSelector);
        this.context.HasRequiredFieldErrors = window.PayCentral.hasRequiredFieldErrors();
        this.context.HasUpdatedAddress = this.address !== undefined;
        this.context.HasFrame = this.frame !== null;
        this.context.Type = this.paymentMethod.Type;
        this.context.UseDatavault = this.paymentMethod.GatewayId !== null;
        this.context.DoEnrollment = window.PayCentral.handleEnrollmentSubmit();
        this.context.isUKDD = this.isUKDirectDebit();
        //this.context.HasValidToken = this.validToken !== null || this.validToken !== undefined; // this needs more thought on how to implement this 
    }

    isUKDirectDebit() {
        let panel = document.querySelector("div[id$='_UKDirectDebitsPanel']");
        if (panel) {
            return panel.style['visibility'] == 'visible';
        }

        return false;
    }

    // Create a payment method object of the currently selected payment method
    hasSelectedPaymentMehtod(select) {
        if (!select) return false;
        let selectedOption = select.options[select.selectedIndex];
        this.paymentMethod.Type = selectedOption.value.split('^')[0]; // Type of payment method (ie creditcard, bankdraft...)
        this.paymentMethod.GatewayId = selectedOption.getAttribute("data-DataVaultGatewayAccountId"); // ID associated with the gateway
        this.paymentMethod.GatewayName = selectedOption.getAttribute("data-DataVaultAuthorizationGateway"); // Name of the gateway
        this.paymentMethod.Currency = selectedOption.getAttribute("data-currencycode"); // Payment method Currency code

        return true;
    }

    /**
     * @desc handle 3d Secure on-complete action.
     * @param {any} threeDsCode
     */
    handle3dsComplete(threeDsCode) {
        this.threeDSCode.value = threeDsCode;
        this.frame.contentWindow.postMessage("Challenge successful", "*");
    }

    /**
     * @desc handle on-error action.
     * @param {any} message
     */
    handleError(message, isHtml) {
        window.PayCentral.enablePage();
        window.PayCentral.showValidation(message, isHtml);
        window.PayCentral.enableSubmitButton();
    }

    // Fires the submit button 
    fireSubmit() {
        if (this.commandButton && this.commandButtonOnClick) { // Click the command bar button with original event
            this.commandButton.onclick = this.commandButtonOnClick;
            this.commandButton.click();
        }
        else {
            let event = new CustomEvent("doSubmitPayCentral", { bubbles: true });
            document.dispatchEvent(event);
        }
    }

    // Used when the submit button for the paymentcreatordisplay is in the command bar.
    // Pass in the command bar button and the button.onclick event (before we change the event).
    handleCommandButton(button, buttonOnClick) {
        this.commandButton = button;
        this.commandButtonOnClick = this.commandButtonOnClick || buttonOnClick; // Ensure that we don't overwrite the original .onclick event
        this.commandButton.onclick = () => {
            let frame = this.findFrame();
            if (frame) {
                // If we have client side validators, fire those before attempting to submit the payment to PayCentral
                if (typeof Page_ClientValidate === "function" && !Page_ClientValidate()) {
                    // have to reset Page_BlockSubmit or it will block the next autopostback control from posting back
                    Page_BlockSubmit = false;
                    return;
                }
                if (!window.PayCentral.sendPostMessage())
                    window.PayCentral.fireSubmit();
            }
            else {
                window.PayCentral.fireSubmit();
            }
        }
    }

    // Determines when we should post to DV when managing enrollments
    handleEnrollmentSubmit() {
        let status = document.querySelector("select[id$='_ListDonationStatus']") || document.querySelector("span[id$='_LabelDonationStatusData']");
        if (!status) return true;

        if (status.tagName.toUpperCase() != 'SPAN') {
            let selectedValue = "";
            if (status.selectedOptions === undefined) {
                selectedValue = status[status.selectedIndex].value;
            }
            else {
                selectedValue = status.selectedOptions[0].value;
            }

            if (selectedValue === "Canceled") return false;
        }

        let selectedOption = "";
        let option = document.querySelector("select[id$='_ListDonationPaymentOption']");
        if (!option) return true;

        if (option.selectedOptions === undefined) {
            selectedOption = option[option.selectedIndex].value;
        }
        else {
            selectedOption = option.selectedOptions[0].value;
        }
        if (selectedOption !== "new") return false;
        return true;
    }

    /**
     * Returns the selected payment instrument and its associated settings
     * (pulled from the data-attributes attached to the selected dropdown list option).
     *
     * @returns {PaymentInstrument} paymentInstrument - A payment instrument object.
     * @returns {string} paymentInstrument.Id - Gateway provider id.
     * @returns {string} paymentInstrument.GatewayName - Gateway provider name.
     * @returns {string} paymentInstrument.Currency - Currency code attached to selected payment method.
     * @returns {string} paymentInstrument.PaymentMethodType - Payment method type: ["creditcard", "bankdraft"].
     * @returns {boolean} paymentInstrument.RequireThreeDs - Require 3DS Secure Customer Authentication (SCA) [default: false].
     */
    getSelectedPaymentInstrumentData() {
        const paymentMethodSelectorElement = document.querySelector("select[id$='_PaymentMethodDropDown']") || document.querySelector("select[id$='PaymentMethodSelectBox']");
        if (!paymentMethodSelectorElement || !paymentMethodSelectorElement.options[paymentMethodSelectorElement.selectedIndex]) return {};

        const pm = paymentMethodSelectorElement.options[paymentMethodSelectorElement.selectedIndex];
        let paymentInstrument = {
            ProviderId: pm.getAttribute("data-DataVaultGatewayAccountId"),
            ProviderType: pm.getAttribute("data-DataVaultAuthorizationGateway").toUpperCase(),
            Currency: pm.getAttribute("data-currencycode"),
            Name: pm.getAttribute("data-DataVaultAuthorizationGateway").toUpperCase(),
            Type: pm.value.split("^")[0]
        };

        const { supports3Ds, utilize3Ds } = this.supports3DS(paymentInstrument.Currency, paymentInstrument.Provider);
        paymentInstrument.Supports3Ds = supports3Ds;
        paymentInstrument.Utilize3Ds = utilize3Ds;

        return paymentInstrument;
    }

    /**
     * This data comes from the PayCentralFrame control. It is added to the indicated html input element as a json data string.
     *
     * @returns {Object} A DataVaultJson object.
     */
    getDataVaultJsonElementValue() {
        let dataVaultJsonElement = document.querySelector("input[id$='DataVaultJson']"); // Input populated with the json to be passed to DV
        if (!dataVaultJsonElement) dataVaultJsonElement = this.dataVaultJson;
        if (!dataVaultJsonElement || dataVaultJsonElement.value === undefined || dataVaultJsonElement.value === null) return {};

        // the data might not quite have finished loading
        let attempt = 10;
        while (attempt > 0) {
            try {
                return JSON.parse(dataVaultJsonElement.value);
            }
            catch { }
            attempt--;
        }
    }

    /**
     * Returns the cart or donation amount value.
     *
     * @returns {string} A amount value.
     */
    getAmount() {
        const amount =
            document.querySelector("input[id*='txtPaymentAmountEdit']")
            || document.querySelector("span[id*='lblPaymentAmountReadOnly']")
            || document.getElementById("donationAmountFor3DS")
            || document.querySelector("input[id*='AmountReceivedInputControl']")
            || document.querySelector("input[id*='AmountAppliedInputControl']");

        if (amount && (amount.value || amount.innerText)) {
            return amount.value || amount.innerText;
        }
        return null;
    }

    isCreditCard() {
        return this.paymentMethod.Type.toLowerCase().indexOf("creditcard") !== -1;
    }


    getBankAccountType() {
        const type = this.paymentMethod.Type.toLowerCase();

        // Set bank account - "account type" (checking/savings).
        if (type.indexOf("bankdraft") !== -1 || type.indexOf("directdebit") !== -1) {

            const accountTypeDropdown = document.querySelector("select[id$='BankAccountTypeDropDown']");

            if (accountTypeDropdown !== null && accountTypeDropdown !== 'undefined') {
                return document.querySelector("select[id$='BankAccountTypeDropDown']").value;
            } else {
                return "Checking";
            }

        } else {
            return null;
        }
    }

    getIsRecurring() {
        // Set is recurring indicator.
        let isRecurringElement = document.querySelector("[id$='IsRecurring']");
        if (isRecurringElement) {
            return this.convertToBool(isRecurringElement.value);
        }
        else {
            return null;
        }
    }

    getCardholderNameFromInputField() {
        const cardholderNameElement = document.querySelector("input[id$='txtCCName']");
        if (cardholderNameElement)
            return cardholderNameElement.value;
        return null;
    }

    // Helper method - Used by .getIsRecurring().
    convertToBool(s) {
        if (typeof s == "boolean") return s;
        if (typeof s != "string") return undefined;
        if (s.toLowerCase() === "true") return true;
        if (s.toLowerCase() === "false") return false;
        return undefined;
    }


    /**
    * Retrieves the method data, transaction details, and payer information collected during the checkout process.
    * 
    * @returns {object} A PaymentRequestData object.
    */
    getPaymentRequestData() {
        const selectedMethod = this.getSelectedPaymentInstrumentData();
        const amount = this.getAmount();

        // Build legacy PayCentral UI payload.
        const billingDetails = billingDetailsHelper.getBillingDetails();
        const payer = {
            BillingAddress: billingDetails.Address,
            FirstName: billingDetails.Name.FirstName,
            LastName: billingDetails.Name.LastName,
            FullName: billingDetails.Name.FullName,
            CardholderName: this.getCardholderNameFromInputField()
        }


        return {
            PaymentInstrument: {
                Card: {
                    ContactName: payer.CardholderName,
                    Address: payer.BillingAddress
                },
                MethodData: {
                    Name: selectedMethod.Name,
                    Type: selectedMethod.Type,
                    GatewayId: selectedMethod.ProviderId,
                    GatewayProvider: selectedMethod.ProviderType,
                    Supports3Ds: selectedMethod.Supports3Ds
                },
                RetainOnSuccess: true
            },
            TransactionDetails: {
                Payer: payer,
                PayerEmail: null,
                PayerPhone: null,
                PaymentInstrument: {
                    Card: {
                        ContactName: payer.CardholderName,
                        Address: payer.BillingAddress
                    },
                    MethodData: {
                        Name: selectedMethod.Name,
                        Type: selectedMethod.Type,
                        GatewayId: selectedMethod.ProviderId,
                        GatewayProvider: selectedMethod.ProviderType,
                        Supports3Ds: selectedMethod.Supports3Ds
                    },
                    RetainOnSuccess: true
                },
                Total: {
                    Amount: amount,
                    Currency: {
                        CurrencyCode: selectedMethod.Currency
                    }
                },
                // The more data the better - we may want to include additional order information from the paymentRequest SDK in the future.
                Order: {
                    OrderId: null,
                    OrderTotal: {
                        Amount: amount,
                        Currency: { CurrencyCode: selectedMethod.Currency }
                    },
                    ProcessingModel: null,
                    ShippingAddress: {}
                }
            },
            Merchant: {
                TenantId: null,
                MerchantOrigin: window.location.origin
            },
            Options: {
                CaptureMode: null,
                //Country: "pull from DV side",
                PurchaseFlow: this.options.ProcessingFlow,
                Recurring: false, //hardcoded for now - pull from Brian's work.
                Request3DS: true
            }
        };
    }

    getTargetOrigin() {
        try {
            var iframeurl = this.findFrame()?.src;
            if (iframeurl === undefined) {
                console.log("unable to find DataVault iframe");
                return "*";
            }
            let dvdomain = iframeurl.match(/^https:\/\/[^/]+/)[0];
            return dvdomain;
        } catch (e) {
            console.log("paycentral getTargetOrigin error=" + e);
            return "*";
        }
    }

    /**
     * Build and return payment requests and ui options data object.
     * @param {any} options
     */
    refreshOptions(options) {
        const dvJson = this.getDataVaultJsonElementValue();

        options.MerchantOrigin = window.location.origin;
        options.PartnerOrigin = dvJson.PayCentralUiUrl;
        this.options = options;
        return options;
    }

    /**
     * Builds and returns the pay central iframe src string.
     * @param {any} src - source url for pay central ui.
     * @param {Object} options - ui options data.
     */
    buildTargetSrc(src, options) {
        if (options.ProcessingFlow == "Capture") {
            src = src.replace("widget/CreditCard", "widget/CreditCard/capture");
        }
        if (options.customCss) src = src + "&css=true";
        if (options.custom3DS) src = src + "&3ds=true";
        src += "&flow=" + options.ProcessingFlow;
        return src;
    }

    /**
     * Create widget iframe window.
     * @param {any} src
     * @param {any} options
     */
    buildAndAddWidget(src, options) {
        const targetElement = document.querySelector(`div[id$='${options.ModuleTarget}']`);

        if (!targetElement) return;

        const iframe = document.createElement("iframe");
        iframe.id = "iFrame";
        iframe.name = "iFrame-PayCentral";
        iframe.src = src;
        iframe.style.width = "100%";
        iframe.style.height = "100px";
        if (iframe.src.toLowerCase().includes("widget/mandate")) {
            iframe.style.height = "200px";
        }
        iframe.style.marginLeft = "-9px";
        iframe.style.border = "0";
        iframe.style.display = options.hide === true ? "none" : "normal";
        iframe.referrerPolicy = "strict-origin-when-cross-origin";
        iframe.scrolling = "no";

        // Add module to DOM.
        this.addModule(targetElement, iframe);

        // add a handler that will be called every time a new page is loaded into the frame
        this.frameUnresponsive = false;
        iframe.addEventListener("load", () => {
            // handle older instances of the frame
            if (this.findFrame() !== iframe) return;
            this.frameContentCounter++;
            this.monitorFrameContent();
        });

    }

    RemoveWidget() {
        const matchingElements = document.getElementsByName("iFrame-PayCentral");
        matchingElements.forEach(e => e.remove());
    }

    /**
    * Append widget iframe to the target element.
    * @param {*} targetElement 
    * @returns 
    */
    addModule(targetElement, iframeElement) {
        targetElement.prepend(iframeElement);
    }

    /**
     * Create pay central ui and add module to window.
     * @param {any} src - source url for pay central ui.
     * @param {Object} optionsData - ui options data.
     */
    CreateUI(src, optionsData) {
        console.log("src", src, "optionsData", JSON.stringify(optionsData));

        this.src = src;
        this.lastOptionsData = this.deepClone(optionsData);

        if (src) {
            src = this.buildTargetSrc(src, optionsData);

            const options = this.refreshOptions(optionsData);
            this.buildAndAddWidget(src, options);
        }
    }

    useDisableCheckout() {
        return jQuery("#DataVaultDisableCheckout")?.val()?.toLowerCase() === 'true';
    }
    /**
      * Disable the page whilst a payment is being processed
      */
    disablePage() {
        if (!this.useDisableCheckout()) return;
        this.createOverlay();
    }

    /**
     * Enable the page after payment processing has completed, or an error needs to be displayed
     */
    enablePage() {
        if (!this.useDisableCheckout()) return;
        this.destroyOverlay();
    }

    createOverlay() {
        this.destroyOverlay();

        // clear validation errors
        this.clearMessages();

        // Create modal dialog and all children elements 
        const processingPaymentMessage = jQuery("#PaymentProcessingMessage").val();
        const element = document.createElement("div");
        element.id = `paymentProcessingStatus_${this.overlaySeq++}`;
        element.className = "modal fade";
        element.setAttribute("role", "status");
        element.innerHTML = '<div class="modal-dialog modal-dialog-status modal-dialog-centered"><div class="modal-content">'
            + '<div class="modal-body"><span class="spinner-dark"></span><h4>' + processingPaymentMessage + '</h4></div>'
            + '</div></div>';
        document.body.appendChild(element);

        // if a 3DS challenge UI kicks in then hide the overlay message
        // when the 3DS challenge goes away, show the message again 
        const overlayHandler = (mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    if (Array.from(mutation.addedNodes).some(is3dsOverlay)) {
                        this.hideOverlay();
                        return;
                    }
                    if (Array.from(mutation.removedNodes).some(is3dsOverlay)) {
                        this.showOverlay();
                        return;
                    }
                }
                if (this.areValidationErrors()) {
                    this.destroyOverlay();
                }
            }
            function is3dsOverlay(e) {
                return e.tagName.toLowerCase() === "div" && e.id.startsWith("PayCentral-overlay-");
            }
        };

        this.overlay = {
            element: element,
            observer: new MutationObserver(overlayHandler)
        };

        this.showOverlay();
        this.overlay.observer.observe(document.body, { childList: true });

    }

    showOverlay() {
        if (!this.overlay?.element) return;
        jQuery(this.overlay.element).modal({ backdrop: 'static', keyboard: false }, 'show');
    }

    hideOverlay() {
        if (!this.overlay?.element) return;
        jQuery(this.overlay.element).modal('hide');
    }

    destroyOverlay() {
        if (this.overlay?.element) {
            jQuery(this.overlay.element).modal('hide');
            this.overlay.element.remove();
        }
        if (this.overlay?.observer) {
            this.overlay.observer.disconnect();
        }
        this.overlay = null;
    }

    // detect scenarios where the iframe is loaded with content other than the widget whilst the overlay is being displayed
    // if this is detected, tear down the overlay
    async monitorFrameContent() {

        const frame = this.findFrame();
        if (!frame) return;

        const frameContentCounter = this.frameContentCounter;
        const valid = await this.ping();

        // if the frame changed whilst we were waiting for a response, then bail out
        // this would happen if the user changes payment method whilst waiting for a ping response
        if (this.findFrame() !== frame) return;

        // this.frameContentCounter gets incremented anytime new content is loaded into the frame
        // if the counter has changed since we awaited the ping then we have moved along in the process and we just exit out here
        // there will be more events queued up to check the newly loaded content
        if (frameContentCounter !== this.frameContentCounter) {
            return;
        }

        this.frameUnresponsive = !valid;

        // if we did not get a response from the ping, it would indicate that the widget is not loaded into the frame
        // if we are using an overlay then tear it down and display a message
        if (!valid && this.overlay) {
            console.warn("The PayCentral frame appears to contain something other than the payment widget. Tearing down the page overlay.");
            this.handleFrameUnresponsive();
        }
    }

    handleFrameUnresponsive() {
        this.clearMessages();
        this.handleError("An error occurred while submitting the payment request.<br>Please reload the page and try again.<br><a href='javascript:location.reload();' class='TextButton'>Reload</a>", true);
    }

    areValidationErrors() {
        try {
            const errors = jQuery(this.getMessageContainer()).text();
            return errors.length > 0;
        } catch (error) {
            return false;
        }
    }

    async ping() {

        const frame = this.findFrame();

        if (!frame?.contentWindow) {
            console.warn("The PayCentral frame has not been established. Unable to ping.");
            return false;
        }

        const id = ++this.pingCounter;
        let timeout;

        try {

            return await new Promise((resolve, reject) => {
                this.registerPingHandler(id, () => resolve(true));
                frame.contentWindow.postMessage("ping-" + id, "*");
                timeout = setTimeout(() => reject("ping timed out"), 5000);
            });

        } catch (e) {
            console.warn(`Ping attempt failed with ${JSON.stringify(e)}`);
            return false;
        } finally {
            if (timeout) clearTimeout(timeout);
            this.destroyPingHandler(id);
        }

    }

    registerPingHandler(id, action) {
        this.destroyPingHandler(id);
        this.pingHandlers.push({
            id: id,
            action: action,
            active: true
        });
    }

    destroyPingHandler(id) {
        const i = this.pingHandlers.findIndex((h) => h.id === id);
        if (i !== -1) {
            this.pingHandlers[i].active = false;
            this.pingHandlers.splice(i, 1);
        }
    }

    handlePingResponse(response) {
        if (!response.startsWith("pong-")) return;
        const id = Number(response.substring(5));
        if (isNaN(id)) return;
        const i = this.pingHandlers.findIndex((h) => h.id === id && h.active);
        if (i !== -1) {
            const handler = this.pingHandlers[i];
            handler.active = false;
            this.pingHandlers.splice(i, 1);
            handler.action(response);
        }
    }

    deepClone(o) {
        try {
            if ("structuredClone" in window && typeof window["structuredClone"] === "function") {
                return window["structuredClone"](o);
            }
        } catch (e) { }
        try {
            return JSON.parse(JSON.stringify(o));
        } catch (e) { }
        // it won't clone, unlikely
        return o;
    }

}

window.PayCentral = new PayCentralManager();

// export for unit tests
export default PayCentralManager;
            