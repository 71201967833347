
const addressList = [];

const defaultBillingAddress = {
    addressLines: null,
    cityName: null,
    countryCode: null,
    countryName: null,
    countrySubEntityCode: null,
    countrySubEntityName: null,
    postalCode: null
};

const updateStatus = { instance: 0, updated: false };


/*  BEGIN PUBLIC METHODS  */

export function setBillingDetails(address, name, instance) {

    if (!instanceAddressHasUpdate(instance)) {

        const billingDetails = mapToSdkBillingDetails(address, name);
        if (billingDetails) {
            console.log("+ set billing address", JSON.stringify(billingDetails)); //Todo: REMOVE ME
            addressList.push(billingDetails);
        }

    } else { //Todo: REMOVE ME
        console.log("(address has been updated and will not be reset)", instance); //Todo: REMOVE ME
    }
}


export function updateBillingAddress(address, instance) {

    const billingDetails = mapToSdkBillingDetails(address);

    if (billingDetails) {

        // Todo: We've added the next 4 lines with the hope that it will remove out dependency on the .getBillingName() method below; which feels overly hacky. We shall see...
        // We are only updating the billing address, so if we have a preferred name we'll retain it.
        const preferredBillingDetails = addressList[addressList.length - 1];
        if (preferredBillingDetails) {
            billingDetails.name = preferredBillingDetails.name;
        }

        console.log("++ update billing address", JSON.stringify(billingDetails)); //Todo: REMOVE ME
        addressList.push(billingDetails);

        setInstanceAddressUpdateStatus(instance, true);
    }
}

export function getBillingDetails(format = "PascalCase") {

    let billingDetails = undefined;

    // Try get preferred.
    const preferredBillingDetails = addressList[addressList.length - 1];
    if (preferredBillingDetails) {
        billingDetails = preferredBillingDetails;
    }

    // Try get from user input.
    if (!billingDetails) {
        billingDetails = tryGetBillingDetailsFromUserInput();
    }

    // Else default.
    if (!billingDetails) {
        billingDetails = defaultBillingAddress;     
    }

    console.log("getBillingDetails", JSON.stringify(billingDetails)); //Todo: REMOVE ME
    // Format (with or without SDK)
    if (format === "camelCase") {
        return billingDetails;
    } else {
        return toPascalCase(billingDetails.address, billingDetails.name);
    }
}

/*  BEGIN PRIVATE METHODS  */

function mapToSdkBillingDetails(address, name) {

    if (isEmpty(address) && isEmpty(name))
        return null;

    const billingDetails = {
        name: {
            firstName: name?.FirstName ?? null,
            lastName: name?.LastName ?? null,
            fullName: name?.FullName ?? getBillingFullName(name)
        },
        address: {
            addressLines: address?.AddressLines ?? [],
            cityName: address?.CityName ?? null,
            countryCode: address?.CountryCode ?? null,
            countryName: address?.CountryName ?? null,
            countrySubEntityCode: address?.CountrySubEntityCode ?? null,
            countrySubEntityName: address?.CountrySubEntityName ?? null,
            postalCode: address?.PostalCode ?? null
        }
    };

    return billingDetails;
}


// I'd like to get rid of this, but right now it's the easiest way to keep things consistent.
function toPascalCase(address, name) {

    const billingDetails = {
        Name: {
            FirstName: name?.firstName ?? null,
            LastName: name?.lastName ?? null,
            FullName: name?.fullName ?? null
        },
        Address: {
            AddressLines: address?.addressLines ?? [],
            CityName: address?.cityName ?? null,
            CountryCode: address?.countryCode ?? null,
            CountryName: address?.countryName ?? null,
            CountrySubEntityCode: address?.countrySubEntityCode ?? null,
            CountrySubEntityName: address?.countrySubEntityName ?? null,
            PostalCode: address?.postalCode ?? null
        }
    };

    return billingDetails;
}


//todo: this is the one
function tryGetBillingDetailsFromUserInput() {
    // inputs
    const allFirstName     = document.querySelectorAll("input[id$='_FirstName']");
    const allLastName      = document.querySelectorAll("input[id$='_LastName']");
    const allAddressLines0 = document.querySelectorAll("input[id$='_TextAddress1']");
    const allAddressLines1 = document.querySelectorAll("input[id$='_TextAddress2']");
    const allAddressLines2 = document.querySelectorAll("input[id$='_TextAddress3']");
    const allCity          = document.querySelectorAll("input[id$='_TextCity']");
    const allPostalCode    = document.querySelectorAll("input[id$='_TextPostalCode']");
    const allSubEntityName = document.querySelectorAll("input[id$='_TextSubEntity']");
    // selects
    const allCountryDropdowns   = document.querySelectorAll("select[id$='_SelectCountry']");
    const allSubEntityDropdowns = document.querySelectorAll("select[id$='_SelectSubEntityCode']");


    const userInputBillingDetails = [];


    for (let index = 0; index < allCountryDropdowns.length; index++) {

        const countryOption = getDropdownOption(allCountryDropdowns, index);
        // Guard.
        if (!countryOption || countryOption.value == null || countryOption.value === "") continue;
        

        // Build possible address.
        const billingDetails = {
            name: {
                firstName: getInputValue(allFirstName, index),
                lastName:  getInputValue(allLastName, index)
            },
            address: {
                addressLines: [
                    getInputValue(allAddressLines0, index),
                    getInputValue(allAddressLines1, index),
                    getInputValue(allAddressLines2, index)
                ],
                cityName: getInputValue(allCity, index),
                postalCode: getInputValue(allPostalCode, index),
                countrySubEntityName: getInputValue(allSubEntityName, index),
                countryCode: countryOption.value,
                countryName: countryOption.text
            }
        };

        // If we don't have a country sub entity from the input let's try to pull it from a select list.
        if (!billingDetails.address.countrySubEntityName) {
            const countrySubEntityOption = getDropdownOption(allSubEntityDropdowns, index);
            if (countrySubEntityOption) {
                billingDetails.address.countrySubEntityCode = countrySubEntityOption.value;
                billingDetails.address.countrySubEntityName = countrySubEntityOption.text;
            }
        }

        // Clean up our address lines.
        billingDetails.address.addressLines = billingDetails.address.addressLines.filter(line => line && line !== null);

        // Add full name.
        if (billingDetails.name.firstName == null) billingDetails.name.firstName = "";
        if (billingDetails.name.lastName == null) billingDetails.name.lastName = "";

        const isNameFull = (billingDetails.name.firstName != null && billingDetails.name.lastName != null)
        if (isNameFull)
            billingDetails.name.fullName = billingDetails.name.firstName + " " + billingDetails.name.lastName;

        if (isSdkBillingDetailsAllNullEverything(billingDetails)) {
            continue;
        }

        userInputBillingDetails.push(billingDetails);
    }

    // Find the richest billingDetails to pull from -- the one with the most fields filled
    let richestIndex = 0;
    let richestAmount = 0;
    for (let i = 0; i < userInputBillingDetails.length; i++) {
        const richnessOfCurrentBillingDetails = getAmountOfFilledFieldsRecursive(userInputBillingDetails[i]);
        if (richnessOfCurrentBillingDetails > richestAmount) {
            richestIndex = i;
            richestAmount = richnessOfCurrentBillingDetails;
        }
    }

    return userInputBillingDetails[richestIndex]; //✔✔✔
}

const getAmountOfFilledFieldsRecursive = object => Object
                            .values(object)
                            .reduce((s, v) => s + (v && typeof v === 'object'
                                ? getAmountOfFilledFieldsRecursive(v)
                                : +!!v
                            ), 0);

function isEmpty(object) {
    if (typeof object === "undefined" || object == null)
        return true;
    const isEmpty = Object.values(object).every(x => x === null || x === '' || x === undefined || x.length === 0);
    return isEmpty;
}
function isAddressAllNullEverything(domainAddress) {
    return ((typeof domainAddress === "undefined" || domainAddress == null)
        || ((domainAddress.CityName === null && domainAddress.CountryCode === null && domainAddress.CountryName === null && domainAddress.CountrySubEntityName === null && domainAddress.CountrySubEntityCode === null && domainAddress.PostalCode === null)));

}

function isSdkBillingDetailsAllNullEverything(sdkBillingDetails) {
    return ((typeof sdkBillingDetails === "undefined" || sdkBillingDetails == null)
        || ((sdkBillingDetails.lastName === null && sdkBillingDetails.cityName === null && sdkBillingDetails.countryCode === null && sdkBillingDetails.countryName === null && sdkBillingDetails.countrySubEntityName === null && sdkBillingDetails.countrySubEntityCode === null && sdkBillingDetails.postalCode === null)));

}

function instanceAddressHasUpdate(instance) {
    return (updateStatus.updated && updateStatus.instance === instance);
}

function setInstanceAddressUpdateStatus(instance, updated) {
    // Set address update status.
    updateStatus.instance = instance;
    updateStatus.updated = updated;
}

function getBillingFullName(name) {
    if (!name || name.FirstName == null && name.LastName == null) return undefined;
    if (name.FirstName == null) name.FirstName = "";
    if (name.LastName == null) name.LastName = "";
    return name.FirstName + " " + name.LastName;
}



// Todo: Move all methods below to a helper class so they are reusable.

/**
* 
* @param {NodeList} elementsNodeList
* @param {int} index
* @returns
*/
function getInputValue(elementsNodeList, index) {
    if (index > (elementsNodeList.length - 1)) return undefined;

    if (elementsNodeList[index]?.value?.length > 0) {
        return elementsNodeList[index].value;
    } else {
        return undefined;
    }
}

/**
 * 
 * @param {NodeList} elementsNodeList
 * @param {int} index
 * @returns
 */
function getDropdownOption(elementsNodeList, index) {

    if (index > (elementsNodeList.length - 1)) return undefined;

    const selectElement = elementsNodeList[index];
    const result = {
        value: selectElement.options[selectElement.selectedIndex].value,
        text: selectElement.options[selectElement.selectedIndex].text
    };

    // Filter null, empty string, and "(None)".
    if (result.text == null || result.text === "" || result.text.includes("None")) {
        result.text = null;
    }
    if (result.value == null || result.value === "") {
        result.value = null;
    }

    return result;
}
